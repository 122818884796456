export default {
  "layout": {
    "product_imgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور المنتج"])},
    "header": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسيه"])},
      "howWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجاتنا"])},
      "wordOfBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة مجلس الادارة "])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخبار"])},
      "ourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتنا"])},
      "ourValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمنا"])},
      "ourIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هويتنا"])},
      "ourHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخنا"])},
      "ourTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا"])},
      "economicActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط الاقتصادي"])},
      "legalAffairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشؤون القانونيه"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
      "HumanResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموارد البشرية"])},
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم"])},
      "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئلة الشائعه"])},
      "DownloadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات للتحميل"])},
      "searchForProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن منتج"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
      "aboutGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المجموعة"])}
    },
    "footer": {
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك في البريد"])},
      "sendToUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل لنا"])},
      "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركائنا"])}
    },
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "writeUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال الاسم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "writePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال رقم الجوال"])},
    "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال البريد الإلكتروني"])},
    "messageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع الرساله"])},
    "writeMessageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال موضوع الرساله"])},
    "yourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك"])},
    "writeYourMassge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك هنا"])},
    "ChairmanSpeech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة رئيس مجلس الإدارة"])},
    "FilesToDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات للتحميل"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "generalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عامه"])},
    "communicationFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات التواصل"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "BranchSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبيعات الفروع"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح الكل"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضم إلينا"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموارد البشرية"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الذاتية"])},
    "enterCV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإرفاق السيرة الذاتيه"])},
    "validMailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الايميل بشكل صحيح"])}
  },
  "news": {
    "moreNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الاخبار"])},
    "newsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخبار"])},
    "anotherNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخبار اخري"])}
  },
  "products": {
    "moreCtegory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الاقسام"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "selectCategoey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر القسم"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض"])},
    "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رتب بالإسم"])},
    "productNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايوجد منتجات"])},
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزات"])},
    "useTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة حرارة الاستخدام"])},
    "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستخدامات"])},
    "StandardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل قياسية"])},
    "productNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المنتج"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطر الداخلي( ملم )"])},
    "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط التشغيلي"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
    "workPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماكة ( ملم )"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطول ( متر )"])},
    "explosive_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط الانفجاري"])}
  },
  "about": {
    "howWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "ourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتنا"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمنا"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجوده"])},
    "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلم"])},
    "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الابتكار"])},
    "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاداء"])},
    "ourIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هويتنا"])},
    "ourHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخنا"])},
    "beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البداية"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحاضر"])},
    "ourGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهدافنا"])},
    "EconomicActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط الإقتصادي"])},
    "legalAffairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشؤون القانونيه"])},
    "legalAffairsNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الشؤون القانونية"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])}
  },
  "help": {
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطر الداخلي( ملم )"])},
    "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماكة ( ملم )"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
    "workPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط التشغيلي"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطول ( متر )"])},
    "explosive_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط الانفجاري"])}
  }
}
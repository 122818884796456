export default {
  "layout": {
    "product_imgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product images"])},
    "header": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "howWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we wre"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products"])},
      "wordOfBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word of board "])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "ourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our massage"])},
      "ourValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our values"])},
      "ourIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity"])},
      "ourHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "ourTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
      "economicActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic activity"])},
      "legalAffairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal affairs"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "HumanResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human resources"])},
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common questions"])},
      "DownloadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download files"])},
      "searchForProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for product"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "aboutGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Group"])}
    },
    "footer": {
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe To Mail"])},
      "sendToUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to us"])},
      "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
    },
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "writeUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write User Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "writePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Phone"])},
    "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Email"])},
    "yourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Massage"])},
    "messageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Subject"])},
    "writeMessageSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Message Subject"])},
    "writeYourMassge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Your Massge"])},
    "ChairmanSpeech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman's Speech"])},
    "FilesToDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files to download"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "generalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
    "communicationFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Files"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "BranchSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Sales"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV"])},
    "enterCV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter CV"])},
    "validMailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])}
  },
  "news": {
    "moreNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More News"])},
    "newsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "anotherNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another News"])}
  },
  "products": {
    "moreCtegory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Categories"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
    "selectCategoey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Categoey"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
    "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort By Name"])},
    "productNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Not Found"])},
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantages"])},
    "useTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Temperature"])},
    "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uses"])},
    "StandardDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard details"])},
    "productNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product code"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inside ( mm )"])},
    "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenght ( mm )"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "workPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickness ( mm )"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["working pressure ( mm )"])},
    "explosive_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explosive pressure"])}
  },
  "about": {
    "howWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we are"])},
    "ourMassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Massage"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our values"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality"])},
    "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science"])},
    "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovation"])},
    "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
    "ourIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Identity"])},
    "ourHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our History"])},
    "beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Beginning"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the present"])},
    "ourGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Goals"])},
    "EconomicActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic Activity"])},
    "legalAffairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Affairs"])},
    "legalAffairsNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Affairs No"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "help": {
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inside ( mm )"])},
    "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickness ( mm )"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "workPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["working pressure ( mm )"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height ( meter )"])},
    "explosive_pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explosive pressure"])}
  }
}